import { javascript } from '@api/index'
import { defineStore, getActivePinia } from 'pinia'
import { createParticipantStore } from '@stores/generic/participant.store'
import { useDocxSettings, useHydration } from '@stores/utils'
import { computed, ref } from 'vue'
import { uniqueArray } from '@avvoka/shared'

export type DocumentStoreType = ReturnType<typeof useDocumentStore>
export const allDocumentStores = ref<Array<DocumentStoreType>>([])

type DocumentType = Backend.Models.Document & {
  participants: Backend.Models.Participant[]
  removed_participants: Backend.Models.Participant[]
  avv_roles: Array<Backend.Questionnaire.ParticipantRole>
  comments: ReadonlyArray<Backend.Models.Comment>
  validations: {
    editable: boolean
    character_autoreplacement: {
      setup: boolean
      enabled: boolean
      rules: {
        from: string
        to: string
      }[]
    }
    title_character_limit: {
      setup: boolean
      enabled: boolean
      min: number
      max: number | false
    }
    body_character_limit: {
      setup: boolean
      enabled: boolean
      min: number
      max: number | false
    }
    character_whitelist: {
      setup: boolean
      enabled: boolean
      whitelist: string
      applies_to_title: boolean
    }
  }
  activity_log: {
    at: string
    message: string
  }[]
  drafts: Backend.Models.Draft[] | null
  settings_for_frontend: {
    docx_nego_exported_by: string[]
  }
}

export const createDocumentStore = (uniqueId: string | number) => {
  const documentStore = defineStore(
    'document-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<DocumentType>(javascript.document)
      const hydrateById = (
        id: number,
        fields: ReadonlyArray<keyof DocumentType> = [],
        force: boolean = false
      ) => {
        return hydration.hydrate({ id }, fields, force)
      }
      allDocumentStores.value.push(
        documentStore as unknown as DocumentStoreType
      )

      const roles = hydration.hydratedComputed('avv_roles')

      return {
        ...hydration,
        hydrateById,
        id: hydration.hydratedComputed('id'),
        roles,
        parties: computed(() => uniqueArray(roles.value.map((p) => p.party))),
        isAgreementOnly: computed(() => {
          return roles.value.every(
            (role) =>
              role.rights.edit !== 'All' && role.rights.edit !== 'Comments'
          )
        }),
        participants: hydration.hydratedComputed(
          'participants',
          (participants) =>
            participants.map((participant) => {
              const participantStore = createParticipantStore(participant.id)(
                getActivePinia()
              )
              participantStore.hydratedData = participant
              participantStore.hydrated = true
              return participantStore
            })
        ),
        removedParticipants: hydration.hydratedComputed('removed_participants'),
        validations: hydration.hydratedComputed('validations'),
        activityLog: hydration.hydratedComputed('activity_log'),
        drafts: hydration.hydratedComputed('drafts'),
        settings: hydration.hydratedComputed('settings_for_frontend'),
        templateVersionId: hydration.hydratedComputed('template_version_id'),
        ...useDocxSettings(hydration)
      }
    }
  )
  return documentStore
}

export const useDocumentStore = createDocumentStore('current')

export const getCurrentDocumentId = (): number => {
  // Check if documentStore is hydrated
  const documentStore = useDocumentStore(getActivePinia())
  if (
    documentStore.hydrated &&
    documentStore.hydratedData &&
    documentStore.hydratedData.id != null
  )
    return documentStore.hydratedData.id

  // Otherwise fetch from hidden field
  const documentId = document.getElementById('document-id')
  if (documentId == null) throw new Error('#document-id not found')
  if (!documentId.hasAttribute('value'))
    throw new Error('Value attribute not found on #document-id')
  return +documentId.getAttribute('value')!
}
